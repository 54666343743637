@import '../app-grid/app-grid.component.scss';

kendo-grid-toolbar button span.k-button-text {
    line-height: 0;
}

.k-grid-header {
    @apply bg-quaternary;
}
.k-tabstrip .k-grid-toolbar,
.k-tabstrip .k-grid {
    @apply rounded-none;
}

kendo-grid {
    // TODO refactor with overlapping rounded rule in global kendo.scss
    @apply overflow-hidden rounded-lg;
}
