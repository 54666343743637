app-notification-list {
    .k-svg-i-caret-alt-down {
        @apply text-muted;
    }

    kendo-expansionpanel.k-expanded .k-icon {
        @apply text-muted;
    }

    .k-tabstrip-items-wrapper {
        @apply border-0 pe-4 ps-2 text-sm font-medium;
    }

    .k-tabstrip-top > .k-tabstrip-items-wrapper {
        @apply border-b-0;
    }
}
