import { Component, Input } from '@angular/core';
import { LabelPosition } from '../input.type';

/**
 * Input label component.
 * @param label - The label.
 * @param labelPosition - The position of the label.
 * @param for - The for attribute.
 * @param isRequired - Whether the input is required.
 */
@Component({
    selector: 'app-input-label',
    templateUrl: './input-label.component.html'
})
export class InputLabelComponent {
    @Input() label: string;
    @Input() labelPosition: LabelPosition;
    @Input() for: string;
    @Input() isRequired: boolean = false;
}
