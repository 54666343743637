import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

export enum MimeType {
    Excel = 'application/vnd.ms-excel',
    Pdf = 'application/pdf',
    Image = 'image/png',
    Text = 'text/plain'
}

@Component({
    selector: 'app-upload-action-buttons',
    template: `
        <div class="inline-flex items-center gap-1">
            @if (canDownload(file)) {
                <app-button class="px-0" variant="link" tooltip="Download" (clicked)="download(file)">
                    {{ file.name }}
                </app-button>
            }
            @if (canPreview(file)) {
                <app-button variant="link" size="sm" icon="faSolidEye" tooltip="Preview" (clicked)="preview(file)" />
            }
            @if (canRemove()) {
                <app-button variant="danger" size="sm" icon="faSolidUpload" tooltip="Replace" (clicked)="remove()" />
            }
        </div>
    `
})
export class UploadActionButtonsComponent {
    static previewableExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'tcs', 'ccs'];
    @Input() file: any;
    @Input() disabled: boolean;
    @Output() public removeFile = new EventEmitter<any>();

    public static download(name: string, content: string) {
        this.downloadBlob(name, this.createBlob(content, undefined));
    }

    public static preview(name: string, content: string) {
        if (!this.isFilePreviewable(name)) {
            this.download(name, content);
            return;
        }
        const type = name.toLowerCase().endsWith('pdf')
            ? MimeType.Pdf
            : name.toLowerCase().endsWith('tcs')
              ? MimeType.Text
              : MimeType.Image;
        const link = this.createBlobLink(name, this.createBlob(content, type));
        window.open(link.href, '_blank');
    }

    public static downloadBlob(name: string, blob: Blob) {
        this.createBlobLink(name, blob).click();
    }

    private static createBlobLink(name: string, blob: Blob) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        return link;
    }

    private static createBlob(content: string, type: string) {
        const binaryString = window.atob(content);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new Blob([bytes], { type });
    }

    public static isFilePreviewable(fileName): boolean {
        return this.previewableExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
    }

    public static canPreview(file): boolean {
        return (file?.id > 0 || !!file?.content) && this.isFilePreviewable(file.name);
    }

    constructor(private http: HttpClient) {}

    canRemove(): boolean {
        return !this.disabled;
    }

    remove(): void {
        this.removeFile.emit(this.file);
    }

    canDownload(file): boolean {
        return file?.id > 0 || !!file?.content;
    }

    canPreview(file): boolean {
        return UploadActionButtonsComponent.canPreview(file);
    }

    async download(file) {
        const attachment: any =
            file.id > 0
                ? await firstValueFrom(
                      this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`)
                  )
                : file;
        UploadActionButtonsComponent.download(attachment.name, attachment.content);
    }

    async preview(file) {
        const attachment: any =
            file.id > 0
                ? await firstValueFrom(
                      this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`)
                  )
                : file;
        UploadActionButtonsComponent.preview(attachment.name, attachment.content);
    }
}
