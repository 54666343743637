import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
    selector: 'app-editor-input',
    templateUrl: './editor-input.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EditorInputComponent extends BaseInputComponent<string> {
    /**
     * If set to `false`, the Editor will run in style non-encapsulated mode. This means
     * that the styles of the page will be persisted in the Editor and its content will be affected by them.
     * @default false
     */
    @Input() iframe = false;
}
