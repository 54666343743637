@if (settingKey.toLowerCase() === 'dateformat') {
    <span class="mb-1 text-xs text-gray-500">Example: {{ dateFormatExample }}</span>
    <app-control
        type="select"
        [options]="dateFormats"
        [(ngModel)]="settingValue"
        (valueChange)="updateDateFormatExample($event)" />
}
@if (timeFormat) {
    <span class="mb-1 text-xs text-gray-500">Example: {{ timeFormatExample }}</span>
    <app-control
        type="select"
        [options]="timeFormats"
        [(ngModel)]="settingValue"
        (valueChange)="updateTimeFormatExample($event)" />
}
