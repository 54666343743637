import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-link',
    template: `
        <a class="block h-full w-full cursor-pointer !text-link" [routerLink]="route" [queryParams]="queryParams">
            <ng-content></ng-content>
        </a>
    `
})
export class AppLinkComponent {
    @Input() route: string | string[] | any[];
    @Input() queryParams: Record<string, number | string>;
}
