<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        @if (isMultiple) {
            <kendo-multiselect
                *subscribe="query$ as query"
                [ngClass]="generateInputStyle()"
                [id]="id"
                [title]="title | translate"
                [textField]="textField"
                [valueField]="valueField"
                [fillMode]="'flat'"
                [valuePrimitive]="true"
                [filterable]="true"
                [data]="filteredOptions()"
                [popupSettings]="popupSettings"
                [clearButton]="!isRequired"
                [readonly]="isDisabled"
                [loading]="query?.isLoading"
                [(ngModel)]="value"
                (valueChange)="onValueChange($event)"
                (filterChange)="queryCodelistDebounced($event)">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <div class="p-1">
                        <label class="k-checkbox-label">{{ dataItem.label | translate }}</label>
                    </div>
                </ng-template>
                <kendo-multiselect-messages [noDataText]="'No data found' | translate" />
            </kendo-multiselect>
        } @else {
            <kendo-combobox
                data-container="body"
                *subscribe="query$ as query"
                [ngClass]="generateInputStyle()"
                [id]="id"
                [title]="title | translate"
                [textField]="textField"
                [valueField]="valueField"
                [valuePrimitive]="true"
                [data]="filteredOptions()"
                [filterable]="true"
                [clearButton]="!isRequired"
                [disabled]="isDisabled"
                [readonly]="isDisabled"
                [loading]="query?.isLoading"
                [popupSettings]="popupSettings"
                [(ngModel)]="value"
                (valueChange)="onValueChange($event)"
                (filterChange)="queryCodelistDebounced($event)">
                <kendo-combobox-messages [noDataText]="'No data found' | translate" />
            </kendo-combobox>
        }
        <app-input-error [error]="error()?.[0]" />
    } @else {
        <div class="pb-1 pt-1.5">
            {{ formatedSelectedOptions }}
        </div>
    }
</app-input-label>
