export enum SettingKeyCodes {
    AppBackgroundImage = 'AppBackgroundImage',
    LoginBackgroundImage = 'LoginBackgroundImage',
    UserRegistration = 'UserRegistration',
    ForgetPassword = 'ForgetPassword',
    TermsAndConditions = 'TermsAndConditions',
    NoticeBoard = 'NoticeBoard',
    Chat = 'Chat',
    FAQ = 'FAQ',
    DocumentManagement = 'DocumentManagement',
    ShortCompanyName = 'ShortCompanyName',
    LongCompanyName = 'LongCompanyName',
    Logo = 'Logo',
    LanguageFlag = 'LanguageFlag',
    CertificateLogin = 'CertificateLogin',
    GoogleLogin = 'GoogleLogin',
    AppleLogin = 'AppleLogin',
    FacebookLogin = 'FacebookLogin',
    DateFormat = 'DateFormat',
    TimeFormat = 'TimeFormat',
    Currency = 'Currency',
    Grid = 'Grid'
}
