import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { IconKey } from '@common/classes/icons';
import {
    AdministrationPermissions,
    CodeListPermissions,
    FaqPermissions,
    NoticeBoardPermissions
} from '@common/classes/permissions';
import { PermissionType } from '@common/models/user';
import { PropertyFunction } from '@common/models/util.interface';
import { SVGIcon } from '@progress/kendo-svg-icons';

export interface NavItem extends PanelBarItemModel {
    readonly permissions?: PermissionType | PermissionType[];
}

export const NAVIGATION: NavItem[] = [
    {
        title: _('Dashboard'),
        icon: 'faSolidInbox',
        path: '/home',
        id: 'dashboard',
        selected: true,
        content: 'Tets'
    },
    {
        title: _('Notifications'),
        icon: 'faSolidBell',
        path: '/notice-board/notification',
        id: 'notifications-userlist',
        permissions: [NoticeBoardPermissions.View]
    },
    {
        title: _('FAQ'),
        icon: 'faSolidCircleQuestion',
        path: '/faq',
        id: 'faq',
        permissions: [FaqPermissions.View]
    },
    {
        title: _('Administration'),
        icon: 'faSolidGear',
        id: 'administration',
        permissions: [AdministrationPermissions, CodeListPermissions, FaqPermissions],
        children: [
            {
                title: _('Users'),
                icon: 'faSolidUsers',
                path: '/administration/user',
                id: 'users',
                permissions: [AdministrationPermissions.User]
            },
            {
                title: _('Roles'),
                icon: 'faSolidUserPlus',
                path: '/administration/role',
                id: 'roles',
                permissions: [AdministrationPermissions.Role]
            },
            {
                title: _('Organizations'),
                icon: 'faSolidBuilding',
                path: '/administration/organization',
                id: 'permissions',
                permissions: [AdministrationPermissions.Organization]
            },
            {
                title: _('Codelists'),
                icon: 'faSolidList',
                path: '/administration/codelists',
                id: 'codelists',
                permissions: [CodeListPermissions.View]
            },
            {
                title: _('Application Settings'),
                icon: 'faSolidGear',
                path: '/administration/application-setting',
                id: 'application-settings',
                permissions: [AdministrationPermissions.Settings.View]
            },
            {
                title: _('Faq Categories'),
                icon: 'faSolidTags',
                path: '/administration/faq/categories',
                id: 'faq-categories',
                permissions: [FaqPermissions.Action.Save]
            }
        ]
    }
];

const flatten = (members: NavItem[]): NavItem[] => {
    let children = [];

    return members
        .map((m) => {
            if (m.children && m.children.length) {
                children = [...children, ...m.children];
            }
            return m;
        })
        .concat(children.length ? flatten(children) : children);
};

export const NAVIGATION_FLAT = flatten(NAVIGATION);

export interface PanelBarItemModel {
    // Sets the title of the PanelBar item.
    title: string;

    // Allows the component to set the `"id"` property of each item.
    id: string;

    //Defines the icon that will be rendered next to the title.
    icon: IconKey;

    // Defines the icon that will be rendered next to the title by using a custom CSS class.
    iconClass?: string;

    // Defines an SVGIcon to be rendered.
    // The input can take either an [existing Kendo SVG icon](slug:svgicon_list) or a custom one.
    svgIcon?: SVGIcon;

    // Defines the location of the image that will be displayed next to the title.
    imageUrl?: string;

    // When set to `true`, disables a PanelBar item.
    disabled?: boolean;

    // When set to `true`, expands the PanelBar item.
    expanded?: boolean;

    // Sets the focused state of a PanelBar item.
    focused?: boolean;

    // Sets the selected state of a PanelBar item.
    selected?: boolean;

    // Specifies if the item will be hidden.
    hidden?: boolean | PropertyFunction<boolean>;

    // Defines the child items as an array.
    children?: PanelBarItemModel[];

    // Sets the content of the PanelBar item.
    content?: any;

    // Sets permissions for the PanelBar item.
    readonly permissions?: PermissionType | PermissionType[];

    // Sets the path of the PanelBar item.
    path?: string;
}
