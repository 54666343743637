<div
    [ngClass]="{
        flex: true,
        'flex-col items-start justify-center': labelPosition === undefined || labelPosition === 'top',
        'gap-2': labelPosition === 'right' || labelPosition === 'left'
    }">
    @if (label !== undefined && label !== '') {
        <label
            for="{{ for }}"
            [ngClass]="{
                'ng-star-inserted text-no-wrap select-none text-xs font-normal text-neutral-400': true,
                'order-2': labelPosition === 'right'
            }">
            {{ label | translate }}
            {{ isRequired ? '*' : '' }}
        </label>
    }
    <ng-content></ng-content>
</div>
