import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `
        <div *ngIf="isBusy">
            <div class="loader"></div>
        </div>
        <div *ngIf="!isBusy">
            <ng-container *ngTemplateOutlet="detailRef"></ng-container>
        </div>
    `,
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @ContentChild(TemplateRef, { static: true }) detailRef;
    @Input() isBusy = true;
}
