import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/app-setting-key.codes';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment.base';

@Pipe({
    name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
    defaultDateFormat: string = environment.settings.appControl.dateFormat;
    defaultTimeFormat: string = environment.settings.appControl.timeFormat;
    defaultDateTimeFormat: string = `${this.defaultDateFormat} ${this.defaultTimeFormat}`;

    constructor(
        private datePipe: DatePipe,
        private commonService: CommonService
    ) {
        this.getDefaultDatetimeFormat();
    }

    private getDefaultDatetimeFormat() {
        const settings = this.commonService.queryService.queryClient.getQueryData<Setting[]>(['settings']);
        if (!settings) return;
        const defaultDateFormat = settings.find(
            (setting: Setting) => setting.key === SettingKeyCodes.DateFormat
        )?.value;
        if (defaultDateFormat) this.defaultDateFormat = defaultDateFormat;
        const defaultTimeFormat = settings.find(
            (setting: Setting) => setting.key === SettingKeyCodes.TimeFormat
        )?.value;
        if (defaultTimeFormat) this.defaultTimeFormat = defaultTimeFormat;
        this.defaultDateTimeFormat = `${this.defaultDateFormat} ${this.defaultTimeFormat}`;
    }

    transform(value: Date | string): string | null {
        return this.datePipe.transform(value, this.defaultDateTimeFormat);
    }
}
