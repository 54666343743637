<div class="relative m-2 my-0 h-full rounded-lg bg-card text-card-foreground shadow-md">
    <div class="flex h-full flex-col first:shrink-0 last:shrink-0" [@openClose]="isSidebarExpanded ? 'open' : 'closed'">
        <div>
            <div
                class="flex items-center justify-center gap-4 overflow-clip p-2"
                [ngClass]="{ 'justify-between': isSidebarExpanded }">
                <div class="absolute z-10" [@alignToggle]="isSidebarExpanded ? 'show' : 'hide'">
                    <app-button tooltip="Toggle Menu" variant="link" icon="faSolidBars" (clicked)="toggleExpanded()" />
                </div>
            </div>
            <div
                class="flex flex-nowrap items-center justify-start gap-1 overflow-clip object-contain pb-2"
                [@alignLogo]="isSidebarExpanded ? 'show' : 'hide'"
                kendoTooltip
                position="right"
                [showOn]="isSidebarExpanded ? 'none' : 'hover'">
                @if (environmentLogo) {
                    <img class="h-10 w-10 overflow-clip" [src]="environmentLogo" />
                }
                @if (environmentTitle) {
                    <span class="text-nowrap text-lg" [@fade]="isSidebarExpanded ? 'show' : 'hide'">
                        {{ environmentTitle | translate }}
                    </span>
                }
            </div>
        </div>

        <kendo-panelbar
            class="mx-1 !h-full !overflow-auto !overflow-x-clip text-nowrap border-background-darker"
            id="sidebar"
            [selectable]="false">
            @for (item of navigation; track item.id) {
                <kendo-panelbar-item
                    [expanded]="item.expanded"
                    [selected]="item.selected"
                    [ngClass]="{ 'hidden-carret': !isSidebarExpanded }">
                    <ng-template kendoPanelBarItemTitle>
                        <div class="w-full">
                            <a
                                class="flex min-h-9 w-full cursor-pointer items-center gap-4 px-3 py-2 text-card-foreground"
                                kendoTooltip
                                title="{{ item.title | translate }}"
                                position="right"
                                [routerLink]="item.path"
                                [showOn]="isSidebarExpanded ? 'none' : 'hover'"
                                (click)="onItemClick(item)">
                                <div class="relative flex items-center">
                                    <ng-icon class="text-lg brightness-75 hover:text-primary" [name]="item.icon" />
                                    @if (!item.path) {
                                        <ng-icon
                                            class="absolute left-2 top-2 text-accent transition-all"
                                            [@fade]="isSidebarExpanded ? 'hide' : 'show'"
                                            name="faSolidCaretUp"
                                            [ngClass]="{ 'rotate-180': item.expanded }" />
                                    }
                                </div>

                                <span [@fade]="isSidebarExpanded ? 'show' : 'hide'">
                                    {{ item.title | translate }}
                                </span>
                            </a>
                        </div>
                    </ng-template>
                    @for (child of item.children; track child.id) {
                        <kendo-panelbar-item
                            class="text-small mb-0.5"
                            [title]="child?.title | translate"
                            [expanded]="child.expanded"
                            [selected]="child.selected"
                            [ngClass]="{ 'hidden-carret': !isSidebarExpanded }">
                            <ng-template kendoPanelBarItemTitle>
                                <a
                                    class="flex min-h-9 w-full items-center gap-4 px-4 py-1 text-card-foreground"
                                    kendoTooltip
                                    title="{{ child.title | translate }}"
                                    position="right"
                                    [class]="isSidebarExpanded ? 'pl-6' : ''"
                                    [routerLink]="child.path"
                                    [showOn]="isSidebarExpanded ? 'none' : 'hover'"
                                    (click)="onItemClick(child)">
                                    <div class="relative flex items-center">
                                        <ng-icon class="brightness-75 hover:text-primary" [name]="child.icon" />
                                        @if (!(child.path || isSidebarExpanded)) {
                                            <ng-icon
                                                class="absolute left-2 top-2 text-accent transition-all"
                                                name="faSolidCaretUp"
                                                [ngClass]="{ 'rotate-180': child.expanded }" />
                                        }
                                    </div>
                                    <span [@fade]="isSidebarExpanded ? 'show' : 'hide'">
                                        {{ child.title | translate }}
                                    </span>
                                </a>
                            </ng-template>
                        </kendo-panelbar-item>
                    }
                </kendo-panelbar-item>
            }
        </kendo-panelbar>
        <app-sidebar-profile
            [currentUser]="currentUser"
            [isSidebarExpanded]="isSidebarExpanded"
            [darkTheme]="isDarkTheme()"
            (togglePinSidebar)="toggleExpanded()"
            (toggleTheme)="toggleTheme()"
            (uploadImage)="openUploadImageDialog()" />
    </div>
</div>
