app-control {
    .k-input {
        @apply hover:border-primary hover:shadow-none;

        &:focus,
        &.k-focus {
            @apply border-primary shadow-none;
        }

        &.is-invalid {
            @apply border-danger;
        }
    }

    .checkbox {
        &:hover {
            .k-checkbox {
                @apply border-primary;
            }
        }

        .k-checkbox {
            &:disabled {
                @apply brightness-75;
            }

            &:checked {
                @apply border-primary bg-primary shadow-none;
            }
        }
    }

    kendo-fileselect {
        &:hover:not(.disabled, .is-invalid) {
            button.k-upload-button {
                @apply hover:border-primary hover:shadow-none;
            }

            &:focus,
            &.k-focus {
                button.k-upload-button {
                    @apply border-primary shadow-none;
                }
            }
        }

        &.is-invalid {
            button.k-upload-button {
                @apply border-danger;
            }
        }

        &.disabled {
            button.k-upload-button {
                @apply bg-muted text-muted-foreground;
            }
        }
    }
}

.error-tooltip {
    kendo-tooltip {
        @apply bg-danger text-danger-foreground;

        div:not(.k-tooltip-content) {
            @apply text-danger;
        }
    }
}
